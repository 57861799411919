<div class="wrapper-table custom-scroll table-responsive">
	<table class="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-9">
<!--	<table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">-->
		<thead class="border-gray-200 fs-5 fw-bold bg-lighten">
			<tr>
				<th
					scope="col"
					*ngFor="let item of listHeader"
					[attr.data-value]="item.value"
                    [attr.align]="item.align"
                    [class]="item.className"
				>
					{{ item.label }}
				</th>
			</tr>
		</thead>
		<tbody class="fw-6 fw-bold text-gray-600 position-relative">
            <tr class="centered" *ngIf="listBody && !listBody.length">
                <app-image
                    title="loading..."
                    path="/assets/images/temp/loading.svg">
                </app-image>
            </tr>
			<tr
				*ngFor="
					let item of listBody
						| slice
							: (page - 1) * pageSize
							: (page - 1) * pageSize + pageSize
				"
			>
				<ng-container
					*ngIf="bodyTemplateRef"
					[ngTemplateOutlet]="bodyTemplateRef"
					[ngTemplateOutletContext]="{ $implicit: item }">
				</ng-container>
			</tr>
		</tbody>
	</table>
</div>
<div>
	<ngb-pagination
		[collectionSize]="listBody?.length || 0"
		[(page)]="page"
		[pageSize]="pageSize"
		[maxSize]="5"
	></ngb-pagination>
</div>
