<div class="mb-10" [formGroup]='form'>
    <label *ngIf="labelName" [for]="id" class="form-label">{{labelName}}</label>
    <select
        [id]='id || name || ""'
        [attr.name]='name || ""'
        [formControlName]='name || ""'
        [setValue]='inputModel'
        (change)='onChange($event)'
        class="form-select"
    >
        <option *ngIf="placeholder" value=''>{{ placeholder }}</option>
        <option *ngFor='let opt of options' [value]='opt.value'>
            {{ opt.label }}
        </option>
    </select>
    <div *ngIf='form.controls[name || ""]?.invalid &&
             (form.controls[name || ""]?.touched || form.controls[name || ""]?.dirty)'
         class="fv-plugins-message-container invalid-feedback"
    >
        <div [setFormControlError]='{objError: form.controls[name || ""].errors, control: labelName}'></div>
    </div>
</div>
