<app-loading-progress *ngIf="loading"></app-loading-progress>
<div class="mt-5">
    <h3 class="text-center mb-3">
        Protocolo
    </h3>
    <form
        action="#"
        autocomplete="off"
        [formGroup]="form"
    >
        <div>
            <div class="col-lg-12">
                <app-input
                    id="name"
                    name="nameProtocol"
                    placeholder="NOME DO PROTOCOLO"
                    labelName="NOME DO PROTOCOLO*"
                    [form]="form"
                ></app-input>
            </div>

            <div class="col-lg-12">
                <app-input
                    id="description"
                    name="description"
                    placeholder="DESCRIÇÃO DO PROTOCOLO"
                    labelName="DESCRIÇÃO DO PROTOCOLO*"
                    [form]="form"
                ></app-input>
            </div>

            <div class="col-lg-12">
                <app-input
                    id="frontendName"
                    name="frontendName"
                    placeholder="FRONTEND NOME"
                    labelName="FRONTEND NOME*"
                    [form]="form"
                ></app-input>
            </div>

            <div class="col-lg-12">
                <app-input
                    id="frontendDescription"
                    name="frontendDescription"
                    placeholder="FRONTEND DESCRIÇÃO"
                    labelName="FRONTEND DESCRIÇÃO*"
                    [form]="form"
                ></app-input>
            </div>
        </div>

        <div class="border border-bottom-2 border-dark p-10 mb-10 rounded">
            <h3 class="text-center mb-7 fs-40">
                Exames e aparelhos
            </h3>
            <div class="rounded border-light position-relative border mb-9 p-10 row"
                *ngFor="let amount of amountDevices; let idx = index">

                <div class="float-button" (click)="removingDevice(idx)" *ngIf="idx !== 0">
                    <span class="svg-icon svg-icon-3 cursor-pointer">
                        <app-svg name="delete"></app-svg>
                    </span>
                </div>

                <div class="col-lg-12 mb-5">
                    <label class="label-title" [for]="'captured-' + amount">
                        SELECIONE POR ONDE O EXAME VAI SER EFETUADO
                    </label>
                    <ng-select2
                        width="100%"
                        [id]="'captured-' + amount"
                        class="placeholder-gray-500 d-flex align-items-center"
                        (valueChanged)="onCaptured($event, idx)"
                        [data]="optionsCapture"
                        searchable="false"
                        placeholder="CAPTURAR EXAME"
                    ></ng-select2>
                </div>

                <div class="col-lg-6">
                    <label class="label-title" [for]="'deviceId-' + amount">
                        SELECIONE UM APARELHO
                    </label>
                    <ng-select2
                        width="100%"
                        [id]="'device-' + amount"
                        class="placeholder-gray-500 d-flex align-items-center search"
                        (valueChanged)="onDevicesSelecteds($event, idx)"
                        [data]="formatSelect2(listDevices)"
                        placeholder="APARELHOS"
                    ></ng-select2>
                </div>

                <div class="col-lg-6">
                    <div>
                        <label class="label-title" [for]="'exams-' + amount">
                            EXAME FEITO PELO APARELHO
                        </label>
                        <ng-select2
                            width="100%"
                            [id]="'exams-' + amount"
                            [options]="options"
                            class="placeholder-gray-500 d-flex align-items-center"
                            (valueChanged)="onExamsSelects($event, idx)"
                            [data]="formatSelect2(listExamsDefinition)"
                            placeholder="SELECIONE UM EXAME ABAIXO"
                        ></ng-select2>
                    </div>
                </div>
            </div>

            <button
                type="button"
                (click)="addingDevice()"
                class="w-100 btn btn-dark hover-light cursor-pointer"
            >
                Adicionar aparelho
            </button>
        </div>
    </form>
</div>
