<div>
    <app-accordion
        *ngFor="let item of dataForms; let idx = index"
        [title]="item.label ? item.label : 'Campo ' + (idx + 1)"
    >
        <h3 class="text-center">
            {{ item.label ? item.label : 'Campo ' + (idx + 1) }}
        </h3>

        <h4 *ngIf="item.json_name">
            <strong> Nome em estilo json: </strong> {{ item.json_name }}
        </h4>

        <h4>
            <strong> Campo obrigatorio: </strong> {{ item.required === 'false' ? 'Não' : 'Sim' }}
        </h4>

        <h4 *ngIf="item.input_type">
            <strong> Tipo do input: </strong> {{ item.input_type }}
        </h4>

        <h4 *ngIf="item.input_mask">
            <strong> Mascara: </strong> {{ item.input_mask }}
        </h4>

        <h4 *ngIf="item.json_field_is_array">
            <strong> O campo é do tipo array: </strong>
                {{ item.json_field_is_array === 'false' ? 'Não' : 'Sim' }}
        </h4>

        <h4 *ngIf="item.json_object">
            <strong> Items do array: </strong>
            <code> {{ item.json_object | json }} </code>
        </h4>

        <h4 *ngIf="item.input_options && item.input_options">
            <strong> Multiplas escolhas: </strong>
            <code> {{ item.input_options | json }} </code>
        </h4>
    </app-accordion>
</div>
