import { Component, OnInit } from '@angular/core'
import { Client, Users } from '../../../models/users'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { AppState } from '../../../state/app-state'
import {
	HiddenLoading,
	ShowLoading,
} from '../../../state/loading/loading.action'
import { ToastrService } from 'ngx-toastr'
import { HelpersService } from '../../../services/helpers/helpers.service'
import { Select2OptionData } from 'ng-select2'
import { Options } from 'select2'
import { UserService } from '../../../services/user/user.service'
import { listRolesMocks } from '../../../mocks/users'
import { ContractClientService } from '../../../services/client/contract-client.service'

@Component({
	selector: 'app-create-user',
	templateUrl: './create-user.component.html',
	styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
	user?: Users
	form: FormGroup
	genreOptions: any[]
	clients: Client[] = []
	clientsContract: any[] = []
	_reload: boolean = true
	timeout: any
	loading?: boolean = false
	public showRoles: boolean = false
	public startValue: string = ''
	public listRoles: Array<Select2OptionData> = []
	public listClients: Array<Select2OptionData> = []

	listRolesSelected: string[] = []
	listClientsSelected: string[] = []
	cont: number = 0

	// @ts-ignore
	public options: Options
	isSubmitting: boolean = false
	isDoctor: boolean = false

	constructor(
		private formBuilder: FormBuilder,
		private store: Store<AppState>,
		private toast: ToastrService,
		private helpers: HelpersService,
		private userService: UserService,
		private contractClientService: ContractClientService
	) {
		this.form = this.formBuilder.group({
			name: [this.user?.name, [Validators.required]],
			lastName: [this.user?.lastName, [Validators.required]],
			email: [this.user?.email, [Validators.required]],
			cpf: [this.user?.cpf, [Validators.required]],
			password: [this.user?.password, [Validators.required]],
			birthdate: [this.user?.birthdate, [Validators.required]],
			gender: [this.user?.gender, [Validators.required]],
			crmUf: [this.user?.crmUf],
		})

		this.genreOptions = [
			{
				value: 'M',
				label: 'Masculino',
			},
			{
				value: 'F',
				label: 'Feminino',
			},
		]
	}

	ngOnInit(): void {
		this.getAllClients()
		this.listRoles = listRolesMocks

		this.options = {
			width: '300',
			multiple: true,
			tags: true,
		}
	}

	getAllClients() {
		const self = this
		this.loading = true
		self.listClients = []
		this.contractClientService.getValidClientsContracts({
			fnSuccess(data?: any) {
				self.loading = false
				console.log(data)
				self.listClients = data
			},
			fnError(error) {
				console.log(error)
				self.loading = false
			},
		})
	}

	getContractId() {
		const self = this
		this.cont = this.listClientsSelected.length
		this.clientsContract = []
		self.store.dispatch(ShowLoading())
		self.isSubmitting = true

		if (this.listClientsSelected.length) {
			this.listClientsSelected.forEach((idClient: any) => {
				this.contractClientService.getContractIdClient(idClient, {
					fnSuccess(data?: any) {
						self.cont--
						const el = data.map((el: any) => {
							return {
								id: idClient,
								contract_id: el.id,
							}
						})

						self.clientsContract.push(el[0])

						if (self.cont === 0) {
							self.onSubmit()
						}
					},
					fnError(error) {
						console.warn(error)
						self.toast.error(error.error.detail)
						self.isSubmitting = false
					},
				})
			})
		}
	}

	onSubmit(): void {
		const self = this
        // // const date = self.helpers.getDate(this.form.value.birthdate)
        // // const dataEua = self.helpers.FormatStringData(date)
        // console.log(this.form.value.crmUf.slice(0, 6))
        // console.log(this.form.value.crmUf.slice(6, 8))

		if (
			self.form?.valid &&
			this.listRolesSelected.length &&
			this.listClientsSelected.length
		) {
			const documents = [
				{
					document_type: 'cpf',
					document_number: this.form.value.cpf,
					document_uf: '',
				},
			]
			if (this.isDoctor) {
				documents.push({
					document_type: 'crm',
					document_number: this.form.value.crmUf.slice(0, 6),
					document_uf: this.form.value.crmUf.slice(6, 8),
				})
			}

			const data = {
				name: this.form.value.name,
				last_name: this.form.value.lastName,
				email: this.form.value.email,
				documents: documents,
				password: this.form.value.password,
				birthdate: this.form.value.birthdate,
				gender: this.form.value.gender,
				roles: this.listRolesSelected,
				clients: this.clientsContract,
			}

			self.userService.createUser(data, {
				fnSuccess() {
					self.store.dispatch(HiddenLoading())
					self.toast.success('Usuário cadastrado com sucesso')
					setTimeout(() => {
						window.location.href = '/'
					}, 1000)
				},
				fnError(error) {
					self.store.dispatch(HiddenLoading())
					self.toast.error(error.error.detail)
				},
				fnFinalized() {
					self.isSubmitting = false
				},
			})
		} else {
			self.toast.error('Formulário inválido!')
			self.store.dispatch(HiddenLoading())
		}
	}

	changedSelectRoles(e: any) {
		this.listRolesSelected = e
		this.isDoctor = !!e.includes('doctor')
	}

	changedSelectClients(e: any) {
		this.listClientsSelected = e
	}
}
