import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-forms',
  templateUrl: './card-forms.component.html',
  styleUrls: ['./card-forms.component.scss']
})
export class CardFormsComponent implements OnInit {
  @Input() dataForms: any[] = []

  constructor() { }

  ngOnInit(): void { }
}
