<div class="forms">
    <h2 class="text-center mb-6 mt-6"> Campo do formulario </h2>

    <form
        action="#"
        autocomplete="off"
        [formGroup]="form"
    >
        <div>
            <div class="col-lg-12">
                <app-input
                    id="label"
                    name="label"
                    placeholder="ex: CPF"
                    labelName="NOME DO CAMPO"
                    [form]="form"
                ></app-input>
            </div>

            <div class="col-lg-12">
                <app-input
                    id="json_name"
                    name="json_name"
                    placeholder="ex: contacts, documents, etc"
                    labelName="NOME OU TIPO EM JSON"
                    [form]="form"
                ></app-input>
            </div>

            <div class="col-lg-12">
                <app-select
                    id="required"
                    name="required"
                    [options]="options"
                    labelName="ESTE CAMPO É OBRIGATORIO?"
                    [form]="form"
                    [inputModel]="options[0].value"
                ></app-select>
            </div>

            <div class="col-lg-12">
                <app-select
                    id="input_type"
                    name="input_type"
                    [options]="inputTypes"
                    labelName="TIPO DE INPUT"
                    [form]="form"
                    [inputModel]="inputTypes[0].value"
                ></app-select>
            </div>

            <app-input
                id="input_mask"
                name="input_mask"
                placeholder="(##) #####-####"
                labelName="MASCARA DE FORMATAÇÃO"
                [form]="form"
            ></app-input>

            <div class="col-lg-12" *ngIf="visible.list">
                <app-select
                    id="json_field_is_array"
                    name="json_field_is_array"
                    [options]="options"
                    labelName="ESTE CAMPO É ESTILO OBJETO JSON?"
                    [form]="form"
                    (changeValue)="changeIsArray($event, 'list')"
                    [inputModel]="options[0].value"
                ></app-select>
            </div>

            <div class="col-lg-12" *ngIf="visible.options">
                <app-select
                    id="multipleOptions"
                    name="multipleOptions"
                    [options]="options"
                    labelName="ESTE CAMPO É DE MULTIPLA ESCOLHA?"
                    [form]="form"
                    (changeValue)="changeIsArray($event, 'options')"
                    [inputModel]="options[0].value"
                ></app-select>
            </div>

            <div *ngIf="isArray">
                <h5 class="label-title">
                    OBJETOS RELACIONADOS  {{ visible.options ? 'A MULTIPLA ESCOLHA' : 'A LISTA' }}
                </h5>
                <div class="row mb-4" *ngFor="let field of dataFields; let idx = index">
                    <div class="col-5">
                        <input
                            class="form-control"
                            [value]="fieldObject[idx].key"
                            type="text"
                            [placeholder]="visible.options ? 'Feminino' : 'document_type'"
                            (keyup)="setValueAndKeyField('key', $event, idx)"
                            [disabled]="confirmedField[idx]"
                        >
                    </div>
                    <div class="col-5">
                        <input
                            class="form-control"
                            [value]="fieldObject[idx].key ? fieldObject[idx].value : ''"
                            type="text"
                            [placeholder]="visible.options ? 'F' : 'cpf'"
                            (keyup)="setValueAndKeyField('value', $event, idx)"
                            [disabled]="!fieldObject[idx].key || confirmedField[idx]"
                        >
                    </div>
                    <div class="col-2">
                       <div class="d-flex justify-content-center align-items-center w-100 h-100">
                            <button
                                *ngIf="!confirmedField[idx] &&
                                fieldObject[idx].key && fieldObject[idx].value"
                                type="button"
                                class="btn btn-success hover-light cursor-pointer p-1"
                                (click)="confirmField(idx)"
                            >
                                <app-svg name="arrow-success"></app-svg>
                            </button>

                            <button
                                type="button"
                                class="btn btn-danger hover-light cursor-pointer p-1 m-2"
                                (click)="clearField(idx)"
                            >
                                <app-svg name="arrow-danger"></app-svg>
                            </button>
                       </div>
                    </div>
                </div>

                <button
                    class="w-100 btn btn-primary hover-light cursor-pointer p-1 mt-5"
                    type="button"
                    (click)="addingFields()"
                >
                    adicionar outro item
                </button>
            </div>
        </div>

        <div class="d-flex w-100 justify-content-end mt-7">
            <button class="btn btn-success cursor-pointer m-3" (click)="confirmation()">
                Salvar
            </button>
            <button class="btn btn-danger cursor-pointer m-3" (click)="close()">
                Cancelar
            </button>
        </div>
    </form>
</div>
