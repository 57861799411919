<app-card-profile-header
    [client]="client"
    [profileTitle]="itemSelected || 'editar'"
    [editProfileClient]="true"
>
    <div profileHeader>
        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
			<li class="nav-item mt-2" *ngFor="let menu of listMenu; index as i">
				<button
					type="button"
					class="nav-link text-active-primary ms-0 me-10 py-5 bg-white"
					[class.active]="itemSelected === menu.title"
					(click)="setIdActiveItem(menu)"
				>
					{{ menu.title }}
				</button>
			</li>
		</ul>
	</div>

    <div profileContent>
        <app-form-client
            *ngIf="itemSelected === 'editar'"
            [client]="client"
        ></app-form-client>

        <app-contracts
            *ngIf="itemSelected === 'contratos'"
            [client]="client"
        ></app-contracts>

        <app-specialties
            *ngIf="itemSelected === 'especialidades'"
            [specialtiesItems]="specialtiesItems"
            [specialsClient]="specialsClient"
            (reloadSpecialties)='getSpecialties(id)'
        ></app-specialties>

        <div *ngIf="itemSelected === 'protocolos'">
            <div *ngFor="let protocol of protocols" class="px-6">
                <div class="d-flex justify-content-end">
                    <p class="badge mx-1 badge-light-success"
                    [ngClass]="!protocol?.enabled ? 'badge-light-danger ' : 'badge-light-success'">
                        {{ protocol?.enabled ? 'Ativo' : 'Inativo' }}
                    </p>
                </div>
                <ul class="group-protocol">
                    <li>
                        <strong> Name: </strong> <p>{{ protocol?.name }}</p>
                    </li>
                    <li>
                        <strong> Descrição: </strong> <p>{{ protocol?.description }}</p>
                    </li>
                    <li>
                        <strong> Frontend name: </strong> <p>{{ protocol?.frontend_name }}</p>
                    </li>
                    <li>
                        <strong> Frontend description: </strong>
                            <p>{{ protocol?.frontend_description }}</p>
                    </li>
                </ul>
            </div>
            <button (click)="getFormsClient()"> getFormsClient </button>
        </div>

    </div>
</app-card-profile-header>
