import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContractClientService } from 'src/app/services/client/contract-client.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { Select2OptionData } from 'ng-select2';
import { requestCallback } from 'src/app/models/requestCallback';
import { ToastrService } from 'ngx-toastr';
import { Options } from 'select2';

@Component({
  selector: 'app-form-protocol',
  templateUrl: './form-protocol.component.html',
  styleUrls: ['./form-protocol.component.scss']
})
export class FormProtocolComponent implements OnInit {
    @Input() form: FormGroup = new FormGroup(Input())
    @Input() clientId: string = ''
    @Output() submiting = new EventEmitter()
    listExamsDefinition: Array<Select2OptionData> = []
    listDevices: Array<Select2OptionData> = []
    options: Options
    optionsCapture: Array<Select2OptionData>
    amountDevices: number[] = [ 1 ]
    examsSent: { exam: string, protocolId: string }[] = []
    devicesSent: { exam: string, device: string }[] = []
    requests: requestCallback[] = []
    devicesAndExams: { device: string, exams: any[], capturedBy: string }[] = []
    errors: any[] = []
    loading: boolean = false

    constructor(
        private clientService: ContractClientService,
        public helpers: HelpersService,
        private toast: ToastrService,
    ) {
        this.options = {
            width: '100%',
            multiple: true
        }

        this.optionsCapture = [
            { text: 'CAPTURAR EXAME', id: '' },
            { text: 'cabin', id: 'cabin' },
            { text: 'mobile', id: 'mobile' },
        ]
    }

    ngOnInit(): void {
        this.getExamsDefinition()
        this.getDeviceModels()
         this.devicesAndExams.push({ device: '', exams: [], capturedBy: '' })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['clientId'] && this.form.valid) {
            this.onSubmit()
        }
    }

    getDeviceModels() {
        const self = this
        this.clientService.getDeviceModels({
            fnSuccess(data) {
                self.listDevices = data
                self.listDevices.unshift({ text: "APARELHOS", id: '' })
            }, fnError(err) { console.warn(err) }
        })
    }

    getExamsDefinition() {
        const self = this
        this.clientService.getExamsDefinition({
            fnSuccess(data) {
                self.listExamsDefinition = data
            }, fnError(err) {
                console.warn(err)
            }
        })
    }

    public formatSelect2(list: any[]) {
        return list.map((item: any) => ({ text: item.name, id: item.id }))
    }

    onDevicesSelecteds(device: any, idx: number) {
        if(device) {
            this.devicesAndExams[idx].device = device
            this.invalidSubmitEmit()
        }
    }

    onExamsSelects(examsList: any, idx: number) {
        this.devicesAndExams[idx].exams = examsList
        this.invalidSubmitEmit()
    }

    onCaptured(captured: any, idx: number) {
        if(captured) {
            this.devicesAndExams[idx].capturedBy = captured
            this.invalidSubmitEmit()
        }
    }

    invalidSubmitEmit() {
        const { exams, capturedBy, device } = this.devicesAndExams[0]
        if(!exams || !exams.length || !capturedBy || !device) {
            this.submiting.emit({ submit: false })
        } else {
            this.submiting.emit({ submit: true })
        }
    }

    removingDevice(id: number) {
        this.amountDevices.splice(id, 1)
        this.devicesAndExams.splice(id, 1);
    }

    addingDevice() {
        const lastAmount = this.amountDevices[this.amountDevices.length - 1]
        this.amountDevices.push(lastAmount + 1)
        this.devicesAndExams.push({ device: '', exams: [], capturedBy: '' })
    }

    sendProtocolsCLient(idProtocol: string) {
        if(this.clientId && idProtocol) {
            const self = this
            const data = {
                context_id: this.clientId,
                protocol_id: idProtocol
            }
            this.clientService.sendProtocolsCLient(this.clientId, data, {
                fnSuccess(data) {
                    self.toast.success('Protocolos sincronizado com cliente')
                },
                fnError(err) {
                    self.errors.push(err)
                    self.toast.error('Erro na associação do protocolo ao cliente')
                    console.warn(err)
                }
            })
        } else {
            this.toast.error('Erro na associação do protocolo ao cliente')
        }
    }

    sendExam(dataExam: any, requestIndex: number, callback: Function) {
        const { protocol, examDefintion } = dataExam
    	const exam: any = this.examsSent.find((item) => item.exam == examDefintion)
        const self = this

        const data = {
            protocol_id: protocol,
            exam_definition_id: examDefintion,
        }

        if(!exam) {
            this.clientService.sendProtocolsExams(protocol, data, {
                fnSuccess(idProtocolExam: string) {
                    self.examsSent.push({ exam: examDefintion, protocolId: idProtocolExam })
                    self.requests[requestIndex].status = 'finalized'
                    if(callback) callback(idProtocolExam)
                }, fnError(err) {
                    self.errors.push(err)
                    self.toast.error('Erro no envio do exame do aparelho')
                    console.warn(err)
                }
            })
        } else {
            this.requests[requestIndex].status = 'finalized'
            if(callback) callback(exam.protocolId)
        }
    }

    sendDeviceModels(device: string, examId: string, capturedBy: string) {
        const examAndDeviceSent = this.devicesSent.find((item: any) => {
            return item.device === device && item.exam === examId
        })
        const self = this
        const data = {
            device_model_id: device,
            protocol_exam_id: examId,
            captured_by: capturedBy
        }
        if(!examAndDeviceSent) {
            this.devicesSent.push({ exam: examId, device: device })
            this.clientService.sendDeviceModels(examId, data, {
                fnSuccess(data) { console.log('DEVICE MODEL', data) },
                fnError(err) {
                    self.errors.push(err)
                    self.toast.error('Erro no envio do aparelho')
                    console.warn(err)
                }
            })
        }
    }

    mountingRequests(protolcolId: string) {
        let countExams = 0
        this.devicesAndExams.forEach((item) => {
            item.exams.forEach((exam) => {
                const dataExam = { examDefintion: exam, protocol: protolcolId }
                const REQUEST = {
                    id: countExams,
                    fn: (id: number) => {
                        this.sendExam(dataExam, id, (examId: string)=> {
                            this.sendDeviceModels(item.device, examId, item.capturedBy)
                        })
                    },
                    status: 'queue'
                }
                countExams++
                this.requests.push(REQUEST)
            })
        })
        this.statesSendExames()
    }

    statesSendExames() {
        let countRequests = 0
        let interval = setInterval(()=> {
            if(countRequests < this.requests.length) {
                let request = this.requests[countRequests]

                if(request.status == 'finalized') {
                    countRequests++;
                } else {
                    if(request.status == 'queue') {
                        request.status = 'initialized'
                        request.fn(countRequests)
                    }
                }
            } else {
                clearInterval(interval)
                this.loading = false
                // if (!this.errors.length) {
                //     this.helpers.redirectPage(100, `/panel/cliente/${this.clientId}`)
                //     return
                // }
                this.errors.forEach(err => {
                    this.toast.error(err)
                })
                this.submiting.emit({ error: true })
            }
        }, 300)
    }

    onSubmit() {
        const self = this
        const { nameProtocol, frontendDescription, frontendName, description } = this.form.value
        const body = { frontendDescription, frontendName, description }
        const data = {
            name: nameProtocol,
            ... this.helpers.converterCamelCaseToSnake(body)
        }
        if(this.form.valid && this.clientId) {
            this.clientService.sendProtocol(data, {
                fnSuccess({ id }) {
                    self.loading = true
                    self.mountingRequests(id)
                    self.sendProtocolsCLient(id)
                    self.toast.success('Protocolos enviados com sucesso')
                }, fnError(err) {
                    self.toast.error('Erro no envio do protocolo')
                    console.warn(err)
                }
            })
        }
    }
}
