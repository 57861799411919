import { ListHeader } from '../models/list-header'
import { selectType } from '../models/selectType'

export const clientListHeader: ListHeader[] = [
    {
		value: 'online',
		label: '',
		align: 'center',
	},
    {
		value: 'clients',
		label: 'Clientes',
		align: 'center',
		className: 'col-3',
	},
    {
		value: 'memed',
		label: 'Status Memed',
		align: 'center',
		className: 'col-4 align-center',
	},
    {
		value: 'employeesAmount',
		label: 'Qntd. de funcionários',
		align: 'center',
		className: 'col-4 align-center',
	},
]

export const inputTypeList: selectType[] = [
    { label: "preencha o tipo de input", value: ''},
    { label: "button", value: "button"},
    { label: "text", value: "text"},
    { label: "checkbox", value: "checkbox"},
    { label: "date", value: "date"},
    { label: "email", value: "email"},
    { label: "file", value: "file"},
    { label: "image", value: "image"},
    { label: "number", value: "number"},
    { label: "password", value: "password"},
    { label: "radio", value: "radio"},
    { label: "phone or telephone", value: "tel"},
    { label: "url", value: "url"},
]
