import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Callbacks } from '../../models/callbacks'
import { finalize } from 'rxjs/operators'
import { HelpersService } from '../helpers/helpers.service'

@Injectable({
	providedIn: 'root',
})
export class ContractClientService {
	constructor(private http: HttpClient, private helpers: HelpersService) {}

	getValidClientsContracts(callback: Callbacks): void {
		const self = this
		this.http
			.get<any>(`${environment.api}/clients`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
					const itemContract: any = []
					data = data.map((item: any) => {
						return self.helpers.converterSnakeToCamelCase(item)
					})

					data.forEach((item: any) => {
						if (item.contracts.length) {
							itemContract.push({
								id: item.id,
								text: item.name,
							})
						}
					})
					callback.fnSuccess(itemContract)
				},
				(err) => {
					console.warn(err)
					callback.fnError(err)
				}
			)
	}

	getContractIdClient(id: string, callback?: Callbacks): void {
		const params = {
			context_id: id,
		}
		this.http
			.get<any>(`${environment.api}/terms/contracts`, { params })
			.pipe(
				finalize(() => {
					if (callback?.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe(
				(data) => {
                    console.log('GET CONTRAT ID ', data)
					callback?.fnSuccess(data)
				},
				(err) => {
					console.warn(err)
					callback?.fnError(err)
				}
			)
	}

    getContractTypes(id?: string | boolean, callback?: Callbacks) {
        let url = `${environment.api}/terms/contract_types`
        if (id) url = `${environment.api}/terms/contract_types/${id}`

        this.http
            .get<any>(url)
            .pipe(
                finalize(() => {
                    if (callback?.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callback?.fnSuccess(data)
                },
                (err) => {
                    callback?.fnError(err)
                    console.warn(err)
                }
            )
    }

    sendContract(data: any, callback?: Callbacks) {
        this.http
            .post<any>(`${environment.api}/terms/contracts`, data)
            .pipe(
                finalize(() => {
                    if (callback?.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callback?.fnSuccess(data)
                },
                (err) => {
                    callback?.fnError(err)
                    console.warn(err)
                }
            )
    }

    sendProtocol(data: any,  callback?: Callbacks) {
        this.http
            .post<any>(`${environment.api}/protocols`, data)
            .pipe(
                finalize(() => {
                    if (callback?.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callback?.fnSuccess(data)
                },
                (err) => {
                    callback?.fnError(err)
                    console.warn(err)
                }
            )
    }

    sendProtocolsCLient(id: string, data: any, callback?: Callbacks) {
        this.http
            .post<any>(`${environment.api}/clients/${id}/protocols`, data)
            .pipe(
                finalize(() => {
                    if (callback?.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callback?.fnSuccess(data)
                },
                (err) => {
                    callback?.fnError(err)
                    console.warn(err)
                }
            )
    }

    getExamsDefinition(callback?: Callbacks) {
        this.http
            .get<any>(`${environment.api}/exam_definitions`)
            .pipe(
                finalize(() => {
                    if (callback?.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callback?.fnSuccess(data)
                },
                (err) => {
                    callback?.fnError(err)
                    console.warn(err)
                }
            )
    }

    deleteExamsDefinition(id: any, callback?: Callbacks) {
        this.http
            .delete<any>(`${environment.api}/exam_definitions/${id}`)
            .pipe(
                finalize(() => {
                    if (callback?.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callback?.fnSuccess(data)
                },
                (err) => {
                    callback?.fnError(err)
                }
            )
    }

    getDeviceModels(callback?: Callbacks) {
        this.http
        .get<any>(`${environment.api}/device_models`)
        .pipe(
            finalize(() => {
                if (callback?.fnFinalized) callback.fnFinalized()
            })
        )
        .subscribe(
            (data) => {
                callback?.fnSuccess(data)
            },
            (err) => {
                callback?.fnError(err)
                console.warn(err)
            }
        )
    }

    sendProtocolsExams(protocol_id: string, data: any, callback?: Callbacks) {
        this.http
            .post<any>(`${environment.api}/protocols/${protocol_id}/exams`, data)
            .pipe(
                finalize(() => { if (callback?.fnFinalized) callback.fnFinalized() })
            )
            .subscribe(
                async (data) => {
                    callback?.fnSuccess(await data[0].id)
                },
                (err) => {
                    callback?.fnError(err)
                }
            )
    }

    sendDeviceModels(exam_id: string, dataDevice: any, callback?: Callbacks) {
        this.http
            .post<any>(
                `${environment.api}/protocol_exams/${exam_id}/device_models`, dataDevice
            )
            .pipe(
                finalize(() => {
                    if (callback?.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callback?.fnSuccess(data)
                },
                (err) => {
                    callback?.fnError(err)
                    console.warn(err)
                }
            )
    }

    getClientsProtocols(id: string, callback?: Callbacks) {
        this.http
            .get<any>(`${environment.api}/clients/${id}/protocols`)
            .pipe(
                finalize(() => {
                    if (callback?.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data: any) => {
                    callback?.fnSuccess(data)
                },
                (err: any) => {
                    callback?.fnError(err)
                    console.warn(err)
                }
            )
    }

    sendFormsClient(forms: any, callback?: Callbacks) {
        this.http
            .post<any>(`${environment.api}/forms`, forms)
            .pipe(
                finalize(() => {
                    if (callback?.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callback?.fnSuccess(data)
                },
                (err) => {
                    callback?.fnError(err)
                    console.warn(err)
                }
            )
    }

    getFormsClient(callback?: Callbacks) {
        this.http
            .get<any>(`${environment.api}/forms/c9bae0a1-09f8-49cb-81dd-31bcda64cef7`)
            .pipe(
                finalize(() => {
                    if (callback?.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callback?.fnSuccess(data)
                },
                (err) => {
                    callback?.fnError(err)
                    console.warn(err)
                }
            )
    }
}
