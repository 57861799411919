import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { NgxMaskModule } from 'ngx-mask'
import { StoreModule } from '@ngrx/store'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { SetValueDirective } from './directives/set-value.directive'
import { SetFormControlErrorDirective } from './directives/set-form-control-error.directive'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoadingGlobalComponent } from './components/loading/loading-global/loading-global.component'
import { LoadingLocalComponent } from './components/loading/loading-local/loading-local.component'
import { LoadingProgressComponent } from './components/loading/loading-progress/loading-progress.component'
import { InputComponent } from './components/form/input/input.component'
import { CheckboxComponent } from './components/form/checkbox/checkbox.component'
import { SelectComponent } from './components/form/select/select.component'
import { RadiosComponent } from './components/form/radios/radios.component'
import { ModalDefaultComponent } from './components/modals/modal-default/modal-default.component'
import { LoginComponent } from './pages/login/login.component'
import { VersionComponent } from './components/version/version.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { stateReducers } from './state/reducers'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { AuthInterceptorService } from './services/auth/auth-interceptor.service'
import { ImageComponent } from './components/image/image.component'
import { TemplateDefaultComponent } from './layouts/template-default/template-default.component'
import { CheckboxCustomComponent } from './components/form/checkbox-custom/checkbox-custom.component'
import { MenuItemComponent } from './components/aside/menu-item/menu-item.component'
import { LabelGroupMenuItemComponent } from './components/aside/label-group-menu-item/label-group-menu-item.component'
import { UsersComponent } from './pages/panel/users/users.component'
import { PanelComponent } from './pages/panel/panel.component'
import { TableListComponent } from './components/table-list/table-list.component'
import { CardComponent } from './components/cards/card/card.component'
import { TimelineComponent } from './components/timeline/timeline.component'
import { TodoComponent } from './components/todo/todo.component'
import { EmptyComponent } from './components/empty/empty.component'
import { CardProfileHeaderComponent } from './components/cards/card-profile-header/card-profile-header.component'
import { SvgComponent } from './components/svg/svg.component'
import { HeaderComponent } from './components/header/header.component'
import { FeedComponent } from './components/feed/feed.component'
import { MenuSubComponent } from './components/menu-sub/menu-sub.component'
import { TrendsComponent } from './components/trends/trends.component'
import { ModalStepsComponent } from './components/modals/modal-steps/modal-steps.component'
import { ImageCropperModule } from 'ngx-image-cropper'
import { StepsComponent } from './components/steps/steps.component'
import { ChatComponent } from './components/chat/chat.component'
import { ProgressComponent } from './components/progress/progress.component'
import { ComponentsComponent } from './pages/panel/components/components.component'
import { TitleComponent } from './components/title/title.component'
import { TabComponent } from './components/tabs/tab/tab.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { ImageDecryptComponent } from './components/image-decript/image-decrypt.component'
import { ProfileComponent } from './pages/panel/profile/profile.component'
import { CreateUserComponent } from './pages/panel/create-user/create-user.component'
import { ClientsComponent } from './pages/panel/clients/clients.component'
import { NgSelect2Module } from 'ng-select2'
import { ProfileClientComponent } from './pages/panel/profile-client/profile-client.component'
import { FormClientComponent } from './pages/panel/form-client/form-client.component';
import { EditProfilePictureComponent } from './components/edit-profile-picture/edit-profile-picture.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ContractsComponent } from './pages/panel/profile-client/contracts/contracts.component';
import { SpecialtiesComponent } from './pages/panel/profile-client/specialties/specialties.component';
import { FormProtocolComponent } from './components/form-protocol/form-protocol.component';
import { ClientFormsComponent } from './components/client-forms/client-forms.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { CardFormsComponent } from './components/card-forms/card-forms.component'

@NgModule({
	declarations: [
		AppComponent,
		LoadingGlobalComponent,
		LoadingLocalComponent,
		LoadingProgressComponent,
		InputComponent,
		CheckboxComponent,
		SelectComponent,
		RadiosComponent,
		ModalDefaultComponent,
		ImageComponent,
		VersionComponent,
		NotFoundComponent,
		LoginComponent,
		SetValueDirective,
		SetFormControlErrorDirective,
		TemplateDefaultComponent,
		MenuItemComponent,
		LabelGroupMenuItemComponent,
		UsersComponent,
		PanelComponent,
		TableListComponent,
		CardComponent,
		TimelineComponent,
		TodoComponent,
		EmptyComponent,
		CardProfileHeaderComponent,
		SvgComponent,
		HeaderComponent,
		FeedComponent,
		MenuSubComponent,
        CheckboxCustomComponent,
		TrendsComponent,
		ModalStepsComponent,
		StepsComponent,
		ChatComponent,
		ProgressComponent,
		ComponentsComponent,
		TitleComponent,
		TabComponent,
		TabsComponent,
		ImageDecryptComponent,
		ProfileComponent,
		CreateUserComponent,
		ClientsComponent,
		ProfileClientComponent,
        FormClientComponent,
        EditProfilePictureComponent,
        ContractsComponent,
        SpecialtiesComponent,
        FormProtocolComponent,
        ClientFormsComponent,
        AccordionComponent,
        CardFormsComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		CommonModule,
		HttpClientModule,
		LazyLoadImageModule,
		StoreModule.forRoot({ ...stateReducers }, {}),
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMaskModule.forRoot(),
		ToastrModule.forRoot({
			positionClass: 'toast-top-right',
		}),
		NgSelect2Module,
		ImageCropperModule,
        CKEditorModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
