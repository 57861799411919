import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientInfo } from 'src/app/models/client';
import { ClientService } from 'src/app/services/client/client.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ItemMenu } from 'src/app/models/list-menu';
import { Select2OptionData } from 'ng-select2';
import { ContractClientService } from 'src/app/services/client/contract-client.service';
import { Protocol } from 'src/app/models/protocol';

@Component({
  selector: 'app-profile-client',
  templateUrl: './profile-client.component.html',
  styleUrls: ['./profile-client.component.scss']
})
export class ProfileClientComponent implements OnInit {
    client?: ClientInfo
    itemSelected: string = 'editar'
    listMenu: ItemMenu[]
    id: string = ''
    protocols: Protocol[] = []
    specialsClient: Array<Select2OptionData> = []
    specialtiesItems: Array<Select2OptionData> = []

    constructor(
        private route: ActivatedRoute,
        private clientService: ClientService,
        private protocolService: ContractClientService,
        public helpers: HelpersService,
    ) {
        this.listMenu = [
            {
                title: 'editar',
                icon: '',
                link: ''
            },
            {
                title: 'contratos',
                icon: '',
                link: ''
            },
            {
                title: 'especialidades',
                icon: '',
                link: ''
            },
            {
                title: 'protocolos',
                icon: '',
                link: ''
            },
        ]
    }

    ngOnInit(): void {
        this.id = this.route.snapshot.params.id
        this.getClient()
        this.getSpecialties(this.id)
        this.getSpecialties()
    }

    setIdActiveItem(item: ItemMenu): void {
		this.itemSelected = item.title
	}

    getSpecialties(id?: string) {
        const self = this
        this.clientService.getSpecialtiesClient(id || false, {
            fnSuccess(data) {
                if (id) {
                    self.specialsClient = data
                    return
                }
                self.specialtiesItems = data
            }, fnError(e) { console.log('ERROR', e) }
        })
    }

    getClientsProtocols() {
        const self = this
        this.protocolService.getClientsProtocols(this.id, {
            fnSuccess(data) {
                self.protocols = data
            }, fnError(e) { console.log('ERROR', e) }
        })
    }

    getClient() {
		const self = this
		if (this.id) {
			this.clientService.getClient(this.id, {
				fnSuccess(response) {
                    self.client = response
                    self.getClientsProtocols()
                },
				fnError(err) { console.warn(err) },
			})
		}
    }

    getFormsClient() {
        this.protocolService.getFormsClient({
            fnSuccess(data) {
                console.log(data)
            }, fnError(err) {
                console.log(err)
            }
        })
    }
}
