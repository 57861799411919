<!--<app-header [profile]="userData"></app-header>-->
<div class="card-header d-flex justify-content-end mb-5">
	<div class="row w-100">
		<div class="col-lg-9">
			<div class="d-flex align-items-center position-relative w-100 m-0">
				<span
					class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"
				>
					<app-svg name="search"></app-svg>
				</span>
				<input
					type="text"
					class="form-control form-control-solid ps-13"
					(keyup)="searchUsers($event.target)"
					placeholder="Buscar usuario"
				/>
			</div>
		</div>
		<div class="col-lg-3">
			<div
				class="w-100 h-100 d-flex justify-content-end align-items-center"
			>
				<a
					routerLink="/panel/adicionar-novo-usuario"
					class="w-100 btn btn-sm btn-light btn-active-color-dark"
				>
					<span class="svg-icon svg-icon-3">
						<app-svg name="plus"></app-svg>
					</span>
					Adicionar novo usuário
				</a>
			</div>
		</div>
	</div>
</div>

<div class="row">
	<app-table-list [listBody]="listFilters" [listHeader]="listHeaderUsers">
		<ng-template let-item #body>
			<td width="50" class="picture radius">
				<a [routerLink]="'/panel/perfil/' + item.id">
					<app-image-decrypt
						*ngIf="item.profilePictureUrl"
						[load]="true"
						[path]="item.profilePictureUrl"
						[title]="item.name + ' ' + item.lastName"
					></app-image-decrypt>
					<app-image
						*ngIf="!item.profilePictureUrl"
						path="/assets/images/error-image.png"
						[title]="item.name + ' ' + item.lastName"
					></app-image>
				</a>
			</td>
			<td width="230" class="level">
				<span class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
					>{{ item.name || '--' }} {{ item.lastName || '--' }}</span
				>
				<a
					*ngIf="item.email"
					href="#"
					class="text-muted text-hover-primary fw-bold text-muted d-block fs-7"
				>
					<span class="text-dark">Email</span>: {{ item.email }}</a
				>
			</td>
			<td>{{ item.documentCrm || '--' }}/{{ item.crmUf || '--' }}</td>
			<td class="roles">
				<span
					class="badge badge-light-success me-2"
					*ngFor="let data of item.roles"
				>
					{{ data }}
				</span>
			</td>
			<td class="picture group d-flex">
				<div class="symbol-group symbol-hover" *ngFor="let client of sliceClientsView(item.clients)">
					<a
						[routerLink]="'/panel/cliente/' + client.id"
						class="symbol symbol-35px symbol-circle bg-light"
						data-bs-toggle="tooltip"
						title=""
						data-bs-original-title="Emma Smith"
					>
                        <app-image
                            class="picture radius w-50px"
                            *ngIf="client.id"
                            [load]="true"
                            [effectScale]="true"
                            [path]="client.logo || 'none'"
                            [title]="client.name"
                        ></app-image>
					</a>
                    <div class="w-40px h-40px more-items cursor-pointer" *ngIf="!client.id">
                        <h2> {{ client.dots }} </h2>
                        <span class="row">
                            <a
                                *ngFor="let clientCard of client.moreClients"
                                [routerLink]="'/panel/cliente/' + clientCard.id"
                                class="col-4"
                            >
                                <app-image
                                    [load]="true"
                                    [effectScale]="true"
                                    [path]="clientCard.logo || 'none'"
                                    [title]="clientCard.name"
                                ></app-image>
                            </a>
                        </span>
                    </div>
				</div>
			</td>
			<td class="text-end">
				<button
					(click)="open(item.id)"
					class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
				>
					<span class="svg-icon svg-icon-3">
						<app-svg name="delete"></app-svg>
					</span>
				</button>
			</td>
		</ng-template>
	</app-table-list>
</div>
