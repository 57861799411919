import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { inputTypeList } from 'src/app/mocks/client';
import { selectType } from 'src/app/models/selectType'

@Component({
  selector: 'app-client-forms',
  templateUrl: './client-forms.component.html',
  styleUrls: ['./client-forms.component.scss']
})
export class ClientFormsComponent implements OnInit {
    @Input() callbackConfirmation?: Function
    form: FormGroup
    options: any
    dataFields: number[] = [1]
    fieldObject: any = [{ key: '', value: '' }]
    jsonObject: any = []
    inputTypes: selectType[] = inputTypeList
    isArray: Boolean = false
    confirmedField: Boolean[] = [ false ]
    visible: any = { options: true, list: true }
    dataMounted: any
    json_field_is_array: boolean = false

    constructor(private formBuilder: FormBuilder, public activeModal: NgbActiveModal) {
        this.form = this.formBuilder.group({
            label: null,
            json_name: null,
            json_field_is_array: null,
            input_type: null,
            input_mask: null,
            required: null,
            multipleOptions: false,
        })

        this.options = [
            { label: 'Selecione uma opção', value: false },
            { label: 'Sim', value: true },
            { label: 'Não', value: false }
        ]
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.clearFielsAll()
    }

    setValueAndKeyField(type: any, e: any, index: number) {
        const target = e.target.value
        if(type === 'key') {
            this.fieldObject[index].key = target
        } else {
            this.fieldObject[index].value = target
        }
    }

    addingFields() {
        const lastField = this.dataFields[this.dataFields.length - 1]
        this.dataFields.push(lastField + 1)
        this.fieldObject.push({ key: '', value: '' })
        this.confirmedField.push(false)
    }

    confirmField(index: number) {
        const field = this.visible.options
            ? this.formatSelect(index)
            : this.formatObject(index)

        this.jsonObject[index] = field
        this.confirmedField[index] = true
    }

    clearField(index: number) {
        if(this.fieldObject[index].key && this.fieldObject[index].value || index < 1) {
            this.fieldObject[index].key = ''
            this.fieldObject[index].value = ''
            this.confirmedField[index] = false
        } else {
            this.fieldObject.splice(index, 1)
            this.dataFields.splice(index, 1)
            this.jsonObject.splice(index, 1)
            this.confirmedField.splice(index, 1)
        }
    }

    changeIsArray(value: any, type: string) {
        this.isArray = value === 'true'

        if(type === 'options') {
            this.json_field_is_array = value
            this.visible.options = true
            this.visible.list = false

            if(!this.isArray) {
                this.visible.list = true
                this.visible.options = true
                this.clearFielsAll()
            }
        } else if (type === 'list') {
            this.visible.options = false
            this.visible.list = true

            if(!this.isArray) {
                this.visible.list = true
                this.visible.options = true
                this.clearFielsAll()
            }
        }
    }

    mountDataForm() {
        const { multipleOptions, ...form } = this.form.value
        let json_object = null
        let input_options = null

        this.jsonObject = this.removeItemsEmpty()

        if(!this.visible.options) {
            json_object = this.jsonObject
        } else {
            input_options = this.jsonObject
        }

        const json_field_is_array = this.json_field_is_array

        this.dataMounted = {
            json_object,
            input_options,
            json_field_is_array,
            ...form
        }
    }

    formatObject(index: number) {
        const fieldInList = Array(this.fieldObject[index])

        return fieldInList.reduce((acc: any, item: any) => {
            acc[item.key] = item.value
            return acc
        }, {})
    }

    formatSelect(index: number) {
        const valueInArray = Object.entries(this.fieldObject[index])
        const label = valueInArray[0][1]
        const value = valueInArray[1][1]
        return {
            label,
            value
        }
    }

    removeItemsEmpty() {
        if(!this.jsonObject.length) return ''
        return this.jsonObject.filter((field: any) => [field][0])
    }

    clearFielsAll() {
        this.jsonObject = []
        this.fieldObject = [{ key: '', value: '' }]
        this.confirmedField = [false]
        this.dataFields = [1]
    }

    close() {
		this.activeModal.close()
        this.clearFielsAll()
	}

	confirmation() {
		if (this.callbackConfirmation) this.callbackConfirmation()
        this.mountDataForm()
	}
}
