const api = 'https://hml-api.haitech.network'

export const environment = {
    api: `${api}/api/v1/main`,
    memedUrl: 'https://memed.com.br',
    memedApi: 'https://api.memed.com.br/v1',
    ws: 'https://hml.haitech.network',
    redirects: {
        roles: {
            admin: '/panel',
            public: '/',
        },
    },
    env: 'hml',
    production: false,
}
