<app-loading-progress *ngIf="loading"></app-loading-progress>
<div class="card card-body py-5 px-5">
	<form
		action="#"
		autocomplete="off"
		(ngSubmit)="onSubmit()"
        *ngIf="form"
		[formGroup]="form"
	>
		<div *ngIf="client && !contract || !client && !contract">
            <div class="row">
                <div class="col-lg-12">
                    <app-input
                        id="name"
                        name="name"
                        typeInput="text"
                        placeholder="Digite o nome do cliente"
                        labelName="EMPRESA*"
                        [form]="form"
                        [inputModel]="client?.name"
                    ></app-input>
                </div>
                <div class="col-lg-12">
                    <app-input
                        id="appointmentsExpiration"
                        name="appointmentsExpiration"
                        typeInput="number"
                        placeholder="Vencimento da consulta"
                        labelName="VENCIMENTO DA CONSULTA"
                        [form]="form"
                        [inputModel]="client?.appointmentsExpiration"
                    ></app-input>
                </div>
                <div class="col-lg-12">
                    <app-input
                        id="externalConference"
                        name="externalConference"
                        placeholder="Nome da empresa da conferencia externa"
                        labelName="CONFERENCIA EXTERNA"
                        [form]="form"
                        [inputModel]="client?.externalConference"
                    ></app-input>
                </div>
            </div>
            <div>
                <app-select
                    id="memed"
                    name="usesMemed"
                    [options]="statusMemed"
                    labelName="MEMED*"
                    [form]="form"
                    [inputModel]="client?.usesMemed || statusMemed[1].value"
                ></app-select>
            </div>
        </div>
        <div *ngIf="!client || contract">
            <div class="col-lg-12 mb-8"  *ngIf="!client">
                <label class="label-title" for="listSpecialties">ESPECIALIDADES</label>
                <ng-select2
                    *ngIf="listSpecialties.length"
                    width="100%"
                    id="listSpecialties"
                    [options]="options"
                    formControlName="specialties"
                    class="placeholder-gray-500 d-flex align-items-center"
                    name="specialties"
                    [formGroup]="form"
                    [data]="listSpecialties"
                    placeholder="Selecione as especialidades deste cliente"
                >
                </ng-select2>
            </div>
            <div>
                <app-select
                    *ngIf="contractTypesList && contractTypesList.length > 0"
                    id="contractType"
                    name="contractType"
                    [options]="contractTypesList"
                    labelName="TIPO DE CONTRATO*"
                    [form]="form"
                    [inputModel]="contractTypesList[0].value"
                ></app-select>
            </div>
            <div class="mt-3 mb-3">
                <label class="label-title" for="editor">CONTEÚDO DO CONTRATO</label>
                <ckeditor
                    formControlName="content"
                    [editor]='Editor'
                    id="editor"
                    [config]='configEditor'
                    [(ngModel)]='dataCkEditor'
                    [data]='dataCkEditor'
                ></ckeditor>
            </div>
        </div>
		<div
            class="wrapper-button py-5 w-100 d-flex justify-content-center"
            *ngIf="client?.name"
        >
			<button
				type="submit"
				class="btn btn-primary cursor-pointer"
				[disabled]="form.invalid"
            >
                {{ client && !contract ? 'Salvar Alterações' : 'Adicionar Contrato' }}
			</button>
		</div>

        <div *ngIf="!client?.name">
            <app-form-protocol
                [clientId]="client?.id || clientIdSent"
                [form]="form"
                (submiting)="submitingProtocol($event)"
            ></app-form-protocol>


            <div class="border border-bottom-2 border-dark p-10 mb-10 rounded">
                <h3 class="text-center mb-7">  Formulário </h3>

                <app-card-forms *ngIf="dataForms.length" [dataForms]="dataForms"></app-card-forms>

                <p *ngIf="!dataForms.length" class="text-danger text-center">
                    Caso não seja preenchido o formulario, ele será preenchido <br>
                    com um formulario padrão da propria aplicação
                </p>

                <div class="mt-10">
                    <button
                        type="button"
                        class="w-100 btn btn-dark hover-light cursor-pointer mb-2 me-2"
                        (click)="openModalForms()"
                    >
                        Adicionar Campo
                    </button>
                </div>
            </div>

            <div class="wrapper-button py-5 w-100 d-flex justify-content-center">
                <button
                    type="submit"
                    class="btn btn-primary cursor-pointer w-250px"
                    (click)="loadingButtonSubmit()"
                    [disabled]="
                        form.invalid ||
                        submiting ||
                        !submitProtocol
                    "
                >
                    <span *ngIf="!submiting">Criar cliente</span>
                    <span *ngIf="submiting">
                        <app-image class="image-load" title="loading..."
                            path="/assets/images/loading-white.gif"
                        ></app-image>
                    </span>
                </button>
            </div>
        </div>
	</form>
</div>
